header {
    background: linear-gradient(90deg, #1b74f0 0, #0477bd 53.48%, #1b74f0 96.87%);
    box-shadow: 0 6px 12px #bed7ef99;
}
.topbar_bg {
    background: #373737;
}
.topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}
.topbar li {
    display: inline-block;
    margin-right: 10px;
    font-size: 12px;
    position: relative;
}
.topbar li:last-child {
    margin-right: 0;
}
.topbar li:not(:last-child):after {
    content: "|";
    color: #4e382e;
    margin-left: 10px;
}
.topbar li span {
    color: #f2f2f2;
}
.topbar a {
    color: #f2f2f2;
}
li.license {
    cursor: pointer;
}
li.license .license-img {
    overflow: hidden;
    position: absolute;
    top: 30px;
    left: -160px;
    z-index: -1;
    opacity: 0;
    height: 0;
    transition: all 0.8s;
}
li.license:hover .license-img {
    opacity: 1;
    height: 233px;
    z-index: 999;
}
.main_header {
    padding: 1px 0;
}
.main_header .logo {
    height: 70px;
    padding: 5px 0;
}
.main_menu ul {
    display: flex;
    justify-content: space-between;
}
.main_menu ul li {
    display: inline-block;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1.8;
    opacity: 0.8;
}
.main_menu ul li a {
    padding: 10px 20px;
    display: block;
    position: relative;
}
.main_menu ul li .item_title {
    color: #333;
    font-weight: 600;
}
.main_menu ul li .item_title:after {
    background: #1b74f0;
    border-radius: 3px;
    bottom: 8px;
    content: " ";
    display: block;
    height: 4px;
    left: 50%;
    position: absolute;
    transform: translate(-50%);
    transition: width 0.2s;
    width: 0;
}
.main_menu ul li:hover .item_title:after,
.main_menu ul li.active .item_title::after {
    width: 32px;
}
.main_menu ul li:hover .item_title,
.main_menu ul li.active .item_title {
    color: #1b74f0;
}
.main_menu ul li .item_sub {
    color: #666;
}
.menu_login {
    padding: 10px 0;
}
.title-login {
    color: #1b74f0;
    font-weight: 700;
}
.svg-icon {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    overflow: hidden;
    fill: currentColor;
}
.title-login-icon {
    display: inline-block;
    width: 33px;
    height: 33px;
    font-size: 20px;
    margin-right: 7px;
}
.formlogin > div {
    display: inline-block;
    margin-left: 5px;
}
.formlogin input {
    border: 0;
    color: #333;
    background: #f2f2f2;
    border-radius: 5px;
    border: 1px solid #cccccc;
    padding: 4px 10px;
}
.formlogin input::placeholder {
    color: #333;
}
.menu_login > * {
    display: inline-block;
}
.menu-login li {
    display: inline-block;
    margin-left: 20px;
    font-size: 13px;
    color: #fff;
}
.menu-login li span {
}
.menu-login li.item {
    cursor: pointer;
}
.header-notify {
    height: 38px;
    line-height: 38px;
    margin-top: 20px;
}
.header-notify .icon_news {
    background: url(../../static/notice.png);
    display: block;
    width: 64px;
    height: 64px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    left: 5px;
    position: relative;
    top: -5px;
    -webkit-transform: translate(-5px, -5px);
    transform: translate(-5px, -5px);
}
.header-notify .notify-title {
    color: #555;
    font-weight: 500;
    height: 38px;
    line-height: 38px;
    display: flex;
    width: 70px;
}
.header-notify marquee {
    width: calc(100% - 75px);
    color: #00a6f0;
}
.header-noti {
    background: #eef7ff;
    border-radius: 20px;
    box-shadow: inset 0 6px 12px #bed7ef99;
    position: relative;
}
.volumn-top {
    z-index: 11;
    right: 0;
    top: 0.2rem;
    border: 3px solid #fff;
    background: #8cb5f9;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px;
    margin-left: 5px;
    overflow: hidden;
    position: relative;
}
.volumn-top > div {
    height: 100%;
    display: flex;
}
.volumn-top svg {
    font-size: 15px;
    margin: auto;
    color: #fff;
}
.box-login {
    display: flex;
    gap: 10px;
    width: 300px;
}
.btn-login,
.btn-register {
    align-items: center;
    background: url(../../image/blue_btn.png);
    background-size: 100% 100%;
    border: 0;
    box-shadow: none;
    color: #fff;
    font-size: 17px;
    font-weight: 700;
    border-radius: 0;
    text-align: center;
    text-transform: uppercase;
    width: 145px;
    height: 55px;
    line-height: 25px;
    cursor: pointer;
}
.btn-register {
    background-image: url(../../image/red_btn.png);
}
.center-menu {
    display: flex;
}
.center-menu_item {
    width: 85px;
    text-align: center;
    cursor: pointer;
}
.center-menu_item .name {
    font-size: 12px;
    color: #fff;
}
.center-menu_item img {
    width: 36px;
    height: 36px;
}
.user-has-login {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    color: #fff;
}
.user-has-login img {
    height: 20px;
    width: 20px;
}

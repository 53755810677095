.backdrop {
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
}
.menu-popup {
    width: 90%;
    max-width: 1200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    overflow: hidden;
}
.menu-popup-grid {
    display: grid;
    grid-template-columns: 1fr 5fr;
    height: 510px;
}
.menu-popup-grid .menu-sidebar {
    background: #2d3a4c;
}
.menu-popup-grid .menu-sidebar .menu-title {
    padding: 20px 0;
    font-size: 18px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;
    color: #fff;
}
.menu-popup-grid .menu-content {
    position: relative;
    background: #fff;
    height: 510px;
    overflow-y: auto;
}
.menu-popup-grid .menu-sidebar ul li {
    color: #f5f5f5;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
    display: flex;
    align-items: center;
    height: 44px;
    line-height: 44px;
    padding-left: 15px;
}
.menu-popup-grid .menu-sidebar ul li.active {
    background: #fd4e66;
    color: #fff;
}
.menu-popup-grid .menu-sidebar ul li span {
    display: block;
    color: #fff;
    line-height: 1.2em;
    font-size: 16px;
}
.menu-popup-grid .menu-sidebar ul li i {
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align: middle;
    background-image: url(../../image/nav.png);
    position: relative;
    top: 2px;
    margin-right: 3px;
}
.icon_securityCenter {
    background-position: -148px -84px;
}
.icon_depositv2 {
    background-position: -316px -15px;
}
.icon_withdraw {
    background-position: -14px -49px;
}
.icon_personalHistoryCopy {
    background-position: -182px 105px;
}
.icon_transactionHistory {
    background-position: -274px -53px;
}
.icon_personalProfit {
    background-position: -57px -84px;
}
.icon_rewardCenter {
    background-position: -106px -49px;
}
.icon_messageV2 {
    background-position: -148px -48px;
}
.icon_bonusDetails {
    background-position: -182px -85px;
}
.icon_referral {
    background-position: -274px -81px;
}
.icon_agentAnnouncement {
    background-position: -274px -53px;
}
.icon_agentTeamOverview {
    background-position: -14px -49px;
}
.close-popup {
    background: #ccc;
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 5px;
    border-radius: 99%;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    z-index: 3;
}
.close-popup svg {
    width: 20px;
    height: 20px;
    font-size: 20px;
    fill: #fff;
}
.popup-login {
    width: 90%;
    max-width: 1000px;
    height: 680px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #fff;
}
.popup-login-form {
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.popup-login-form .logo {
    width: 135px;
    height: auto;
}
.popup-login-form h1 {
    color: #1b74f0;
    font-size: 20px;
    font-weight: bold;
}
.form-lg .inputs {
    margin: 10px 0;
}
.form-lg .input {
    margin: 0 0 10px;
    position: relative;
}
.form-lg .img, .form-lg .input img {
    height: 24px;
    width: 24px;
    left: 10px;
    position: absolute;
    top: 8px;
    width: auto;
}
.form-lg input[type=radio] {
    width: 16px;
    height: 16px;
    padding: 0;
}
.form-lg input {
    background-color: #fff;
    border: 1px solid #9bd1fd;
    border-radius: 5px;
    color: #333;
    height: 40px;
    padding: 0 20px 0 40px;
    line-height: 40px;
    width: 100%;
    font-size: 15px;
}
.form-lg button {
    align-items: center;
    background: linear-gradient(92.79deg, #61cfff 1.31%, #1b75f0);
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 17px;
    font-weight: 700;
    height: 50px;
    justify-content: center;
    margin: auto;
    overflow: hidden;
    position: relative;
    transition: all 1s ease;
    width: 100%;
}
form .inputs svg {
    color: #666;
    font-size: 22px;
    position: absolute;
    right: 10px;
    top: 8px;
}
.form-lg a {
    color: #1b75f0;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}
.form-lg p {
    color: red;
    font-size: 14px;
    margin: 5px 0 10px;
}